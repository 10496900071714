import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import fixArray from '../../../../../utils/fixArray';
import maxBy from '../../../../../utils/maxBy';
import { ICruiseSpecial } from '../../cruise-search-page/interfaces/icruise-special';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { ProjectService } from '../../../../shared/services/project.service';
import {CabinsByTypePipe} from '../../../../shared/pipes/cabins-by-type.pipe';
import { ICruiseDetail } from '../interfaces/icruise-detail';
import { ICruiseDetailCabin } from '../interfaces/icruise-detail-cabin';
import { ICruiseDetailExcursion } from '../interfaces/icruise-detail-excursion';
import { ICruiseDetailParams } from '../interfaces/icruise-detail-params';
import { ICruiseDetailSolution } from '../interfaces/icruise-detail-solution';
import { ICruiseDetailStep } from '../interfaces/icruise-detail-step';
import { IShipServiceParams } from '../interfaces/iship-service-params';
import { IShipServiceRemote } from '../interfaces/iship-service-remote';
import {ICruiseDetailCabinByType} from "../interfaces/icruise-detail-cabin-by-type";
import {CruiseTopId} from '../../../../shared/models/cruise-top-id.enum';
import {ICruiseSolution} from "../../cruise-search-page/interfaces/icruise-solution";
import {PROMO_CODES} from '../../../../../../assets/data/promo-codes';

@Injectable({
  providedIn: 'root'
})
export class CruiseDetailService {


  constructor(private _http: HttpClient,
    private _projectService: ProjectService,
    private _environmentService: EnvironmentService,
    private _cabinsByType: CabinsByTypePipe) {
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  private getFormattedCruiseName(cruiseTopId: number, cruiseName: string): string {
    // se è una crociera carnival, rimuovo i caratteri speciali dalla stringa
    if (cruiseTopId === CruiseTopId.CARNIVAL) {
      const itineraryString = cruiseName;
      const withoutSquareBrackets = itineraryString.replace(/\[(.*?)\]/g, '');
      const withoutDestination = withoutSquareBrackets.replace(/#.+/g, '');
      const finalItineraryString = withoutDestination;

      return finalItineraryString;
    }

    return cruiseName;
  }

  formatExcursion(excursion: any): ICruiseDetailExcursion {
    return {
      code: excursion.Code,
      description: excursion.Description,
      harbour: { name: excursion.Harbour.Name, id: excursion.Harbour.Id, code: excursion.Harbour.Code },
      duration: excursion.Duration,
      id: excursion.Id,
      level: excursion.Level,
      tags: excursion.Typology?.split(';').map(e => e.trim()),
      title: excursion.Title
    };
  }

  formatCabin(cabin: any): ICruiseDetailCabin {
    return {
      availability: cabin.Availability === 1,
      available: cabin.Available === 1,
      code: cabin.Code,
      description: this.setCabinDescription(cabin) || cabin.Description,
      fare: cabin.Fare,
      fareName: cabin.FareName,
      fsb: parseFloat(cabin.Fsb),
      id: parseInt(cabin.Id, 10),
      price: parseFloat(cabin.MKPriceCP),
      taxes: parseFloat(cabin.Taxes),
      type: cabin.Type,
      isUpdatedFromCategory: false
    };
  }

  setCabinDescription(cabin) {
    let description = '';
    if (!cabin?.Description || cabin.Description.toLowerCase().includes('premium')) {
      description += cabin.Type;
      if (cabin?.SubCategory?.toLowerCase() !== 'premium') {
        description += " " + cabin.SubCategory;
      }
    }
    return description;
  }

  formatSolution(solution: any): ICruiseDetailSolution {
    const arrivalDate = new Date(solution.ArrivalDate);
    const departureDate = new Date(solution.DepartureDate);
    const days = (dayjs(arrivalDate).diff(departureDate, 'days')) + 1;
    const cabins = solution.Cabin_Collection?.Cabin ?
        fixArray(solution.Cabin_Collection.Cabin).map(e => this.formatCabin(e)) : [];

    return {
      arrivalDate,
      arrivalHarbour: { id: solution.ArrivalHarbour.Id, name: solution.ArrivalHarbour.Name },
      cabins,
      code: solution.Code,
      days,
      departureDate,
      departureHarbour: { id: solution.DepartureHarbour.Id, name: solution.DepartureHarbour.Name },
      excursions: solution.Excursions_Collection?.CruiseExcursion
        ? fixArray(solution.Excursions_Collection.CruiseExcursion).map(e => this.formatExcursion(e)) : undefined,
      hasFlight: solution.FlightMandatory === 1,
      id: solution.Id,
      price: parseFloat(solution.MKPriceCP),
      source: solution.Source,
      specials: solution.Specials_Collection.Special
          ? fixArray(solution.Specials_Collection.Special).map(e => this.formatSpecial(e)) : [],
      cabinsGroupedByType: this.getCruiseCabinsGroupedByType(cabins, solution),
      isPriceUpdatedFromCategory: false
    };
  }

  fillSteps(steps: ICruiseDetailStep[]): ICruiseDetailStep[] {
    if (!steps || (steps && steps.length === 0)) {
      return [];
    }
    const firstDay = 1;
    const lastDay = maxBy(steps, 'day').day;
    for (let i = firstDay; i <= lastDay; i++) {
      const currentDay = steps.find(x => x.day === i);
      if (!currentDay) {
        steps.push({
          day: i,
          departureTime: undefined,
          arrivalTime: undefined,
          code: undefined,
          isNavigation: true,
          harbour: undefined,
          lat: undefined,
          lng: undefined
        });
      }
    }
    return steps.sort((a, b) => a.day > b.day ? 1 : -1);
  }

  formatSpecial(special: any): ICruiseSpecial {
    return {
      id: special.Id,
      type: special.Type
    };
  }

  formatSteps(steps: any[]): any[] {

    //  A volte gli orari, quando non disponibili, vengono passati con la stringa '-'. In questi casi li setto a null per uniformità
    steps.forEach(step => {
      if (step.ArrivalHour?.length < 2) step.ArrivalHour = null;
      if (step.DepartureHour?.length < 2) step.DepartureHour = null;
    })

    //  Steps in ordine cronologico
    steps.sort((a, b) => Number(a.DepartureDay) - Number(b.DepartureDay))

    //  Lista dei giorni associati ad una tappa
    const days = [];
    steps.forEach(step => {
      if (!days.includes(step.DepartureDay)) days.push(step.DepartureDay);
      if (!days.includes(step.ArrivalDay)) days.push(step.ArrivalDay);
    })

    //  Dopo aver estratto la lista dei giorni ricostruisco l'itinerario.
    //  facendo in modo che ad ogni tappa sia assegnato un unico porto ed associandole gli orari di partenza ed arrivo corretti
    const fixedSteps = [];
    days.forEach(day => {
      const harbour = steps.find(step => step.ArrivalDay === day)?.ArrivalHarbour || steps[0].DepartureHarbour;
      const ArrivalHour = steps.find(step => step.ArrivalDay === day)?.ArrivalHour || null;
      const DepartureHour = steps.find(step => step.DepartureDay === day)?.DepartureHour || null;
      fixedSteps.push({ day, harbour, ArrivalHour, DepartureHour });
    })

    return fixedSteps.map(e => this.formatStep(e));
  }

  formatStep(step: any): ICruiseDetailStep {
    return {
      day: parseInt(step.day, 10),
      departureTime: step.DepartureHour?.trim(),
      arrivalTime: step.ArrivalHour?.trim(),
      code: step.harbour.Code,
      isNavigation: false,
      harbour: step.harbour.Name,
      lat: parseFloat(step.harbour.Lt),
      lng: parseFloat(step.harbour.Ll)
    };
  }

  formatCruise(cruise: any): ICruiseDetail {
    if (!cruise?.Solution_Collection) {
      return null;
    }

    const solutions = cruise.Solution_Collection.CruiseSolution ?
      fixArray(cruise.Solution_Collection.CruiseSolution).map(e => this.formatSolution(e)) : [];
    const steps = cruise.Steps_Detail.Step ? this.formatSteps(fixArray(cruise.Steps_Detail.Step)) : [];

    return {
      selectedSolution: undefined,
      code: cruise.Code,
      id: cruise.Id,
      name: this.getFormattedCruiseName(cruise.TourOperator?.Id, cruise.Name),
      parentName: cruise.Itinerary_Detail.ParentName,
      seller: cruise.Seller,
      solutions,
      source: cruise.Source,
      steps: this.fillSteps(steps),
      ship: { name: cruise.Ship.Name, id: cruise.Ship.Id, code: cruise.Ship.Code, image: cruise.Ship.Image },
      tourOperator: { name: cruise.TourOperator.Name, id: cruise.TourOperator.Id }
    };
  }

  getFormattedCruiseDetail(searchParams: ICruiseDetailParams, isLanding?): Observable<ICruiseDetail> {
    return (
      searchParams.from ?
        this.getCruiseDetail(searchParams, isLanding) :
        this.getGenericCruiseDetail(searchParams, isLanding)
    )
      .pipe(
        map(e => this.formatCruise(e)),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
      );
  }

  //  Metodo separato per dettaglio generico
  // TODO Idealmente sarebbe meglio uniformare a getCruiseDetail ma si rimanda questo sviluppo
  // a quando la ricerca generica con queryParams sarà rodata e saranno più chiari effettivi requisiti
  getGenericCruiseDetail(searchParams: ICruiseDetailParams, isLanding: boolean): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const apikey = isLanding != undefined && isLanding == true ? 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb' : e[1];
        const url = `${e[0].searchUrl}/index.php/cruise/${apikey}/details/${searchParams.source}/groupfilter/${searchParams.network}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.Cruise),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }


  getCruiseDetail(searchParams: ICruiseDetailParams, isLanding: boolean): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
      this._projectService.hasOnlyNetQuotes(),
      this._projectService.getQuoteType()
    ]).pipe(
      mergeMap(e => {
        const hasOnlyNetQuotes = e[2];
        const quoteType = e[3];
        const apikey = isLanding != undefined && isLanding == true ? 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb' : e[1];
        const url = `${e[0].searchUrl}/index.php/cruise/${apikey}/details/${searchParams.source}/from/${searchParams.from}/to/${searchParams.to}/groupfilter/${searchParams.network}/onlyNetworkQuotes/${hasOnlyNetQuotes}/quoteType/${quoteType}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.Cruise),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getShipService(searchParams: IShipServiceParams): Observable<IShipServiceRemote> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiShipServiceUrl}/index.php/cruise/${e[1]}/shipservice/${searchParams.tourOperatorId}/${searchParams.shipCode}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.result),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCruiseCabinsGroupedByType(cabins: ICruiseDetailCabin[], solution: any): ICruiseDetailCabinByType[] {
    let availableCabins = JSON.parse(JSON.stringify(cabins));

    // filtro prima le cabine che hanno il type impostato
    availableCabins = availableCabins.filter((cabin) => cabin.type);
    const cruisePromoCosta = Array.isArray(solution.specials)
      ? solution.specials.some(special => PROMO_CODES.BEVANDE_GRATIS.ids.indexOf(special.id) >= 0)
      : PROMO_CODES.BEVANDE_GRATIS.ids.indexOf(solution.Specials_Collection?.Special?.Id) >= 0;
    return this._cabinsByType.transform(availableCabins, false, cruisePromoCosta);
  }

  isCostaSpecialPromo(cruise: ICruiseDetail): boolean {
    return cruise.solutions
      ?.filter(solution => solution.specials)
      .some(solution => solution.specials.some(special => PROMO_CODES.BEVANDE_GRATIS.ids.indexOf(special.id) >= 0));
  }

  isBlackFridayPromo(cruise: ICruiseDetail): boolean {
    return cruise?.solutions?.some((sol: ICruiseSolution) => PROMO_CODES.BLACK_FRIDAY.includes(sol?.code));
  }
}
